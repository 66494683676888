.coupons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__section {
    padding-left: 12px;
    flex-basis: 100%;

    &__dashboard {
      flex-basis: 50%;
    }

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }

    .icon__inner svg {
      width: 65px;
      height: 65px;
      .cls-1{
        fill: $clr-ntrl-min;
      }
    }
  }

  &__error-message {
    color: $clr-error-message;
  }

  &__add {
    flex-basis: 10%;
    justify-content: right;

    .icon__inner svg {
      width: 20px;
      height: 20px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;

    .icon-header {
      flex-basis: 90%;

      .icon__inner svg {
        width: 35px;
        height: 35px;

        .cls-1{
          fill: $clr-ntrl-min;
        }

      }

    }
  }

  &__invite-a-friend {
    flex-basis: 50%;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }

  }

  &__input-fields {
    display: flex;
    flex-flow: column;
    flex-basis: 100%;
  }

  &__name {
    display: flex;
    flex-flow: nowrap;
    margin-bottom: 5px;
  }

  &__email {
    display: flex;
    flex-flow: row nowrap;
  }

  &--send-mail {
    .icon__inner svg{
      .cls-1{
        fill: currentColor;
      }
    }
  }

  .action-button {
    margin-left: 0.5rem;
    border: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    background: #fff;
  }

  .notification {
    background-color: $clr-coupon-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 50px;

    .icon__inner svg {
      width: 65px;
      height: 65px;
      .cls-1{
        fill: $clr-ntrl-min;
      }
    }

    .icon {
      margin-right: 0;
    }

    p {
      text-align: center;
      font-weight: bolder;
      margin: 10px 0;
    }

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .icon__inner svg {
        width: 2rem;
        height: 2rem;
        .cls-1{
          fill: currentColor;
        }
      }
    }
  }

  &__empty {
    flex-basis: 50%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }
  }

  &__empty-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color;
    padding: 5px;
  }

  &--empty-message {
    text-align: center;
    vertical-align: center;
    font-family: $font-el-messiri, sans-serif !important;
    font-size: $fs-h6;
    padding: 15px 0;
    font-weight: $fw-bold;
    line-height: 2rem;
  }

  &__list {
    flex-basis: 50%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  }

  &__data-row {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    font-weight: bold;
  }

  &__heading__child {
    flex-basis: 33.33%;
    padding-left: 10px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    border: 1px solid $border-color;
    padding: 5px;
  }

  &__code,
  &__value,
  &__status,
  &__date {
    flex-basis: 25%;
    padding-left: 10px;
  }

  &__view-more {
    flex: 100%;
    display: flex;
    justify-content: center;
  }

  &__view-more-btn {
    margin: 7px 2px;
    padding: 0.9rem;
  }

  &__error {
    display: flex;
    margin-top: 20px;
  }

  &__error-img {
    width: 24px;
    height: 24px;
  }
}

.coupon-form {
  margin-bottom: 10px;
}

.coupon-textfield {
  padding: 8px
}

.credit-payment-result {
  background-color: #F3EEEC;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;

  .icon__inner svg {
    width:65px;
    height: 65px;
  }
}

@use "sass:math";

$action-dimension: 43px;
$items-buttom-border-margin: 7px;

.card-btn svg path{
  fill: black
}
.icon-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.first-heading-first-content{
  margin-left: 24px;
  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
}

.dashboard-heading{
  font-weight: bold;
  display: flex;
  justify-items: start;
  width: 100%;

  .heading-child {
    flex-basis: 20%;
  }
  .heading-action {
    max-width: $action-dimension;
    width: 100%;
    margin-left: 0.5rem;
  }
}
.dashboard-content{
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  .content {
    display: flex;
    width: calc(100% - #{$action-dimension} * 3);
    border: 1px solid $border-color;
    height: $action-dimension;
    margin-bottom: $items-buttom-border-margin;
    display: flex;
    justify-items: start;
    align-items: center;
    .content-child {
      flex-basis: 20%;
    }
  }
}
.action-button{
  @include push--smallest--left;
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $action-dimension;
  height: $action-dimension;
  min-width: $action-dimension;
  min-height: $action-dimension;
  background: $clr-ntrl-min;
  a {
    @include u-svg--link-states($clr-base, $clr-primary, $clr-base);
    display: flex;
  }
  @media only screen and (max-width: 600px) {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $action-button-border-color__mobile;
  }
}
.show-more-btn-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  button {
    background-color: $clr-ntrl-min;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    flex: 1;
  }
}
.item-imgs{
  height: 30px;

  @media only screen and (max-width: 600px) {
    width: 65%;
    height: auto;
  }
}
.view-btn{
  a {
    @include u-svg--link-states($clr-primary, $clr-primary, $clr-primary);
  }
}
%accordion-background{
  background-color: $border-color;
  border: 1px solid $border-color;
  margin-bottom: 10px;
  padding: 10px;
}
.payment-error-msg{
  color: red;
}
.accordion-container{
  @extend %accordion-background;

  .mobile-payment-container {
    display: none;
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  .desktop-payment-container {
    margin-left: 15px;
    .payment__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-and-input {
        display: flex;
        align-items: center;
        flex-basis: 60%;
        .make-payment-container {
          width: 60%;
        }
      }
      .payment-button {
        justify-self: flex-end;
        flex-basis: 40%;
        text-align: end;
      }
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }

  }
  @media only screen and (max-width: 600px) {
      background: $clr-ntrl-min;
      border: none;
      margin-top: 10px;
      padding: 0;
  }
}
.make-payment-input{
  background: $border-color;
  border-bottom: 1px solid;
  @media only screen and (max-width: 600px) {
    background: $clr-ntrl-min;
    border: none;
    border-bottom: 1px solid;
    margin-bottom: 20px;
  }
}
.make-payment-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-weight: bold;
}
.make-payment-input::-moz-placeholder { /* Firefox 19+ */
  font-weight: bold;
}
.make-payment-input:-ms-input-placeholder { /* IE 10+ */
  font-weight: bold;
}
.make-payment-input:-moz-placeholder { /* Firefox 18- */
  font-weight: bold;
}
.make-payment-title{
  @media only screen and (max-width: 600px) {
    color: $clr-brand;
  }
}
.make-payment-button{
  background: $border-color;
  color: $clr-brand;
  border: 1px solid $clr-brand;
  @media only screen and (max-width: 600px) {
    background: $clr-ntrl-min;
    width: 100%;
  }
}
.make-payment-button:hover{
  background:  $clr-brand;
  color: $clr-ntrl-min;
}

.desktop-eft-container {
  @extend %accordion-background;
  display: flex;
  justify-content: space-between;
  padding-left: 25px;

  .eft-container__col-1 {
    flex-basis: percentage(math.div(1, 3));
    display: flex;
    flex-direction: column;
  }
  .eft-container__col-2 {
    flex-basis: percentage(math.div(1, 3));
    display: flex;
    flex-direction: column;
  }
  .eft-container__col-3 {
    flex-basis: percentage(math.div(1, 3));
    text-align: end;
    padding-right: 20px;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobile-eft-container {
  display: none;
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    display: flex;
    .mobile-eft-container__col-1 {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
    }
    .mobile-eft-container__col-2 {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      margin-left: 25px;
      span {
        margin-bottom: 20px;
      }
    }
  }
}


.action-button-active{
  background: $clr-brand;
  color:$clr-ntrl-min;
}
.card-btn-active svg path {
  fill: $clr-ntrl-min;
}
.card-btn-active{
  background: $clr-brand;
}
.show-more-btn{
  font-size: 12px;
  padding: 0.8rem;
  width: 144px;
  color: $clr-brand;
  border: 1px solid $clr-brand;
  background: $clr-ntrl-min;
  @media only screen and (max-width: 600px) {
    text-align: center;
    width: 100%;
  }
}

.orders-icon-header{
  color: $clr-base;
}

.dashboard-accordion-container{
  border: none !important;
}

.dashboard-accordion-container hr{
  display: none;
}

.dashboard-accordion-container > div{
  padding: 0;
}

.dashboard-accordion-container > div:nth-child(2) > div > div{
  padding: 0;
}

.latest-order-desktop-container {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.latest-orders-mobile-container {
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    padding: 20px;
    margin-bottom: 20px;
    .latest-orders__first-row {
      display: flex;
      .order-mobile-headings {
        font-weight: bold;
      }
      .latest-orders__first-row__col-1 {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        span {
          .order-mobile-content {
            margin-left: 10px;
          }
          @media only screen and (max-width: 424px) {
            display: flex;
            flex-direction: column;
            .order-mobile-content {
              margin-left: 0;
            }
          }
        }
      }
      .latest-orders__first-row__col-2 {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        span {
          display: flex;
          text-align: end;
          justify-content: flex-end;
          .order-mobile-headings {
            justify-content: flex-end;
            text-align: end;
            flex-basis: 80%;
          }
          .order-mobile-content {
            margin-left: 10px;
            flex-basis: 20%;
            width: 100%;
          }
          @media only screen and (max-width: 520px) {
            .days-content {
              align-self: end;
            }
          }
          @media only screen and (max-width: 424px) {
            display: flex;
            flex-direction: column;
            .order-mobile-content {
              margin-left: 0;
              justify-content: flex-end;
              text-align: end;
            }
          }
        }
      }
    }
    .latest-orders__second-row {
      display: flex;
      margin-top: 10px;
      flex-flow: row wrap;
      justify-content: space-evenly;
      .action-button {
        margin-left: 0;
        margin-bottom: 10px;
      }
      .view-btn {
        flex-basis: 28%;
      }
      .card-btn {
        flex-basis: 40%;
      }
      .eft-btn {
        flex-basis: 28%;
      }
      .customer-credit-btn {
        flex-basis: 50%;
      }
      .cancel-order-btn {
        flex-basis: 50%;
      }
    }
  }
}

.dashboard-accordion-container__toggle-button__indicator {
  span {
    svg {
      path {
        fill: $clr-brand;
        stroke: $clr-brand;
      }
    }
  }
}

.void-sale-btn{
  .icon__inner svg {
    width: 35px;
    height: 35px;
  }

  &:disabled {
    background-color: $clr-sale-void-disabled;
    opacity: 0.5;
  }
}

.customer-credit-btn {
  &:disabled {
    background-color: $clr-sale-void-disabled;
    opacity: 0.5;
  }
}

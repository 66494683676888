$success-notification-color: transparentize($clr-success, 0.8);
$error-notification-color: transparentize($clr-danger, 0.8);
$base-notification-color: transparentize($clr-warning, 0.8);
$info-notification-color: transparentize($clr-info, 0.8);


$notification-text-line-height: 1.5;

%notification-styling {
  @include soft--smaller--ends;
  @include soft--sides;
  display: flex;
  flex-flow: row nowrap;
  color: $clr-base;
}



.notification {
  @include push--bottom;
  @extend %notification-styling;
  background-color: $clr-light-gray;

  a {
    color: $clr-primary;
    font-weight: bold;
  }
  .icon {
    align-self: center;
    margin-right: 2rem;
  }

  .st0 {
    fill: $clr-ntrl-min;
  }

}

.notification__text-wrapper {
  display: flex;
  align-items: center;
  .notification__text-wrapper__body {
    @include push--smallest--bottom;
    line-height: $notification-text-line-height;
  }

  .notification__text-wrapper__body--no-margin {
    @extend .notification__text-wrapper__body;
    @include u-margin--none;
    strong {
      color: $clr-brand;
    }
  }
}

.notification--success {
  @extend %notification-styling;
  background-color: $success-notification-color;
}

.notification--error {
  @extend %notification-styling;
  background-color: $error-notification-color;
}

.notification--info {
  @extend %notification-styling;
  background-color: $info-notification-color;
}

.notification--available-credit {
  @extend %notification-styling;
  background-color: #e9ede6;
}




